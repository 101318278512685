import cn from 'classnames';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import allocationsIcon from '@/allocate/ui/assets/allocationsIcon.svg';
import { usePermissionsContext } from '@/allocate/application/Allocations/PermissionsContext';
import UploadButton from '@/allocate/ui/components/shared/Upload/UploadButton';
import ErrorsTable from '@/allocate/ui/components/shared/Upload/UploadExcel/ErrorsTable';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import base from '@/Framework/url/baseUrl';
import styles from '@/allocate/ui/components/shared/Upload/UploadExcel/uploadExcel.scss';

const UploadExcel = () => {
  const {
    isAllocationsUploading,
    displayErrors,
  } = useUploadContext();

  const {
    isAdminAnalyticsOnly,
    isAdminBlacklisted,
  } = usePermissionsContext();

  return (
    <div className={ styles.uploadExcelWrapper }>
      <Spinner overlay isVisible={ isAllocationsUploading } />
      { displayErrors ? <ErrorsTable /> : (
        <>
          <img
            src={ allocationsIcon }
            className={ styles.icon }
            alt="Allocations"
          />
          <div className={ styles.title }>
            Upload Final Allocations
          </div>
          <div className={ styles.description }>
            Know your investors like never before.
            Uploading final allocations preserves your data in one authoritative place
            while enabling instant visualization of allocations by deal, account and account types.
          </div>
          <UploadButton
            label="Upload Excel"
          />
          <a
            href={ base.getApiUrl('files/o/static-documents/Allocate/Debt_Allocations_Template.xlsx') }
            className={ cn(styles.downloadTemplate, {
              [styles.disabled]: isAdminAnalyticsOnly || isAdminBlacklisted,
            }) }
            data-test="allocationsUploadExcelDownloadDebtAllocationsTemplate"
          >
            <IconDownload className={ styles.downloadTemplateIcon } />
            <span>
              Download Debt Allocations Template
            </span>
          </a>
          <a
            href={ base.getApiUrl('files/o/static-documents/Allocate/Equity_Allocations_Template.xlsx') }
            className={ cn(styles.downloadTemplate, {
              [styles.disabled]: isAdminAnalyticsOnly || isAdminBlacklisted,
            }) }
            data-test="allocationsUploadExcelDownloadEquityAllocationsTemplate"
          >
            <IconDownload className={ styles.downloadTemplateIcon } />
            <span>
              Download Equity Allocations Template
            </span>
          </a>
        </>
      ) }
    </div>
  );
};

export default UploadExcel;
