import React from 'react';
import isEmpty from 'lodash/isEmpty';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import columns from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/debtColumns';
import { IDebtSecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import styles from '@/allocate/ui/components/shared/Analytics/SecurityDetails/securityDetails.scss';

interface IProps {
  securityDetails: IDebtSecurities,
}

const DebtHeader = ({ securityDetails }: IProps) => {
  const {
    issuerEntity,
    series,
    totalOriginalFace,
    orderAtLaunch,
    subscriptionLevel,
    issuePricingDate,
    transactionTypeId,
    sector,
    industry,
    debtType,
    underwriters,
    upsized,
  } = securityDetails;

  const securityType = {
    sector,
    industry,
    debtType,
    transactionTypeId,
  };

  const columnsConfig = {
    hasOrders: !!orderAtLaunch.length,
    hasUnderwriters: !isEmpty(underwriters),
    rowsCount: totalOriginalFace.length,
  };

  const collection = totalOriginalFace.map((row, index) => {
    if (index === 0) {
      return {
        issuerEntityName: issuerEntity.name,
        underwriters,
        totalOriginalFace: totalOriginalFace[index],
        orderAtLaunch: orderAtLaunch[index],
        subscriptionLevel: subscriptionLevel[index],
        issuerEntityTicker: issuerEntity.ticker,
        series,
        issuePricingDate,
        securityType,
        upsized,
      };
    }

    return {
      totalOriginalFace: totalOriginalFace[index],
      orderAtLaunch: orderAtLaunch[index],
      subscriptionLevel: subscriptionLevel[index],
    };
  });

  return (
    <DataTable
      className={ styles.headerTable }
      data={ collection }
      columns={ columns(columnsConfig) }
      dataTest="securityDetailsGeneralInfoDataTable"
    />
  );
};

export default DebtHeader;
