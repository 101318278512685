import cn from 'classnames';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import Input from '@dealroadshow/uikit/core/components/Input';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import { TCollectionItemsFields } from '@/allocate/domain/vo/Allocations/Upload/DealManagers';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import Buttons from './Buttons';

import { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import styles from '@/allocate/ui/components/shared/Upload/DealManagers/dealManagers.scss';

interface IProps {
  fields: TCollectionItemsFields,
}

const DealManagersRows = ({ fields }: IProps) => {
  const { currentUser } = useSessionContext();
  const { linkedRoadshowId } = useUploadContext();

  return (
    <>
      <div className={ cn(styles.horizontalScrollWrapper, { [styles.disabled]: !!linkedRoadshowId }) }>
        <div className={ styles.scrollContainer }>
          <div className={ styles.tableHeader }>
            <div>Corporate Email</div>
            <div className={ styles.rightSection }>
              { !linkedRoadshowId && (
              <div>
                Invite by email
                <span className={ dataTableStyles.dataTableHeaderIcon }>
                  <Tooltip
                    content="Deal Manager will be sent a notification email with details to access these allocations"
                  >
                    <IconQuestion />
                  </Tooltip>
                </span>
              </div>
            ) }
              <div>
                Analytics only
                <span className={ dataTableStyles.dataTableHeaderIcon }>
                  <Tooltip
                    content="Restrict Deal Manager access to only view analytics. The Deal Manager will not be able to manage or edit the allocations."
                  >
                    <IconQuestion />
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className={ styles.managerList }>
            { fields.map((field, index) => {
              const currentUserRow = fields.value[index].corporateEmail === currentUser.email && index === 0;
              const isRowDisabled = !!linkedRoadshowId || currentUserRow;
              const isInviteByEmailDisabled = !!fields.value[index].managerId;

            return (
              <div
                key={ field }
                className={ cn(styles.managerItem, { [styles.disabled]: isRowDisabled }) }
              >
                <Field
                  name={ `${ field }.corporateEmail` }
                  placeholder="Corporate Email"
                  component={ Input }
                  dataTest={ `corporateEmailInput${ index }` }
                  className={ styles.managerInput }
                  icon={ IconEmail }
                  disabled={ isRowDisabled }
                />
                <div className={ styles.rightSection }>
                  { !linkedRoadshowId && (
                    <Field
                      name={ `${ field }.inviteByEmail` }
                      component={ CheckboxInput }
                      type="checkbox"
                      dataTest={ `inviteByEmailCheckbox${ index }` }
                      className={ styles.managerCheckbox }
                      controlClassName={ styles.managerCheckboxControl }
                      disabled={ isRowDisabled || isInviteByEmailDisabled }
                    />
                  ) }
                  <Field
                    name={ `${ field }.analyticsOnly` }
                    component={ CheckboxInput }
                    type="checkbox"
                    dataTest={ `analyticsOnlyCheckbox${ index }` }
                    className={ styles.managerCheckbox }
                    controlClassName={ styles.managerCheckboxControl }
                    disabled={ isRowDisabled }
                  />
                  { !isRowDisabled && (
                    <span
                      className={ styles.action }
                      data-test={ `deleteDealManager${ index }` }
                      onClick={ () => fields.remove(index) }
                    >
                      <Tooltip content="Delete manager">
                        <IconTrash />
                      </Tooltip>
                    </span>
                  ) }
                </div>
              </div>
            );
          }) }
          </div>
        </div>
      </div>
      <Buttons fields={ fields } />
    </>
  );
};

export default DealManagersRows;
