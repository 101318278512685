import React, { useEffect } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import cn from 'classnames';
import config from '@/Framework/config';
import { AppNavigationItem } from '@/Framework/UI/Templates/AppNavigation';
import Preloader from './Preloader';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/dmPortal/application/config/featureToggle';
import dataroomConfig from '@/dataroom/application/config/config';
import { isGlobalAnalyticsVisible } from '@/dmPortal/application/GlobalAnalytics/config';
import { IApplication } from '@/dmPortal/domain/vo/Application';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext';
import textStyles from '@dealroadshow/uikit/core/styles/text.scss';
import IconUsers from '@dealroadshow/uikit/core/components/Icon/IconUsers';
import IconPieChart from '@dealroadshow/uikit/core/components/Icon/IconPieChart';
import IconPlus from '@dealroadshow/uikit/core/components/Icon/IconPlus';
import { applicationsNavigationConfig } from './constants';
import styles from './navigation.scss';

interface IProps {
  checkUserHasAccessToAccountsAndContactsAnalytics: () => void,
  resetUserHasAccessToAccountsAndContactsAnalytics: () => void,
  isTenantActivated: (tenant: string, collection: IApplication[]) => boolean,
  isActivated: (state: string) => boolean,
  isHidden: (state: string) => boolean,
  hasNoInformation: (state: string) => boolean,
  isUserHasAccessFetching: boolean,
  userHasAccess: boolean,
  isSidebarCollapsed: boolean,
  changeSidebarColor: (color?: string) => void,
  toggleMobileMenu: () => void,
}

const Navigation = (
  {
    checkUserHasAccessToAccountsAndContactsAnalytics,
    resetUserHasAccessToAccountsAndContactsAnalytics,
    isTenantActivated,
    isActivated,
    isHidden,
    hasNoInformation,
    isUserHasAccessFetching,
    userHasAccess,
    isSidebarCollapsed,
    changeSidebarColor,
    toggleMobileMenu,
  }: IProps,
) => {
  const { asPath } = useRouter();
  const { applicationCollection, refreshApplication, isCollectionFetching, isRefreshing } = useNavigationContext();
  const isSidebarAppsFetching = isCollectionFetching || isUserHasAccessFetching;

  useEffect(() => {
    checkUserHasAccessToAccountsAndContactsAnalytics();

    return () => {
      resetUserHasAccessToAccountsAndContactsAnalytics();
    };
  }, []);

  const renderAccountsAndContactsAnalyticsLink = () => (
    <AppNavigationItem
      isSidebarCollapsed={ isSidebarCollapsed }
      icon={ IconUsers }
      id="accounts"
      name="Accounts & Contacts"
      uri="/accounts"
      isExactActive
      isActive={ asPath.startsWith('/accounts') }
      onBecomeActive={ () => changeSidebarColor() }
      onClick={ toggleMobileMenu }
    />
  );

  const renderAnalyticsLink = () => (
    <AppNavigationItem
      isSidebarCollapsed={ isSidebarCollapsed }
      icon={ IconPieChart }
      id="analytics"
      name="Global Analytics"
      uri="/analytics"
      isExactActive
      isActive={ asPath.startsWith('/analytics') }
      onBecomeActive={ () => changeSidebarColor() }
      onClick={ toggleMobileMenu }
    />
  );

  const renderApplications = () => (
    <>
      { applicationCollection
        .map((app, i) => {
          if (
            isHidden(app.state) ||
            (!isActivated(app.state) && !hasNoInformation(app.state))
          ) {
            return null;
          }

          if (app.tenant !== 'finsightWebBondManager') {
            return renderApp(app, i);
          }

          return (
            <AppNavigationItem
              key={ app.tenant }
              isSidebarCollapsed={ isSidebarCollapsed }
              iconSrc={ app.smallIconUrl }
              id={ app.tenant }
              name={ app.name }
              uri={ app.uri }
              onBecomeActive={ () => changeSidebarColor(app.color) }
              onClick={ toggleMobileMenu }
              onRefreshClick={ () => {} }
            >
              { renderApp(
                {
                  ...app,
                  name: 'Analytics',
                  smallIconUrl: null,
                  uri: `${ app.uri }/analytics`,
                },
                applicationCollection.length,
                true,
              ) }
            </AppNavigationItem>
          );
        }) }
      <AppNavigationItem
        isSidebarCollapsed={ isSidebarCollapsed }
        icon={ IconPlus }
        id="applications"
        name="Add App"
        uri="/applications"
        onBecomeActive={ () => changeSidebarColor() }
        onClick={ toggleMobileMenu }
      />
    </>
  );

  const renderApp = (app: IApplication, i: number, isSubItem?: boolean) => {
    const isDisabled = hasNoInformation(app.state);

    if (isDisabled && isRefreshing[i] === true) {
      return renderPreloader();
    }

    const { addNewTooltip, addNewPathname } = applicationsNavigationConfig[app.tenant] ?? {};

    if (app.tenant === dataroomConfig.tenant.tenantResearchRoom.code) {
      return (
        <FeatureToggle
          featureName={ RESEARCHROOM_FEATURE }
          key={ app.uri }
        >
          <AppNavigationItem
            key={ app.uri }
            isSubItem={ isSubItem }
            isSidebarCollapsed={ isSidebarCollapsed }
            iconSrc={ app.smallIconUrl }
            id={ app.tenant }
            name={ app.name }
            uri={ app.uri }
            onBecomeActive={ () => changeSidebarColor(app.color) }
            onClick={ toggleMobileMenu }
            disabled={ isDisabled }
            isActive={ asPath.startsWith(app.uri) }
            isExactActive
            nameCallback={ app.nameCallback }
            onRefreshClick={ () => refreshApplication(app.tenant) }
            { ...app.canCreate && ({
              addNewPathname,
              addNewTooltip,
            }) }
          />
        </FeatureToggle>
      );
    }

    return (
      <AppNavigationItem
        key={ app.uri }
        isSubItem={ isSubItem }
        isSidebarCollapsed={ isSidebarCollapsed }
        iconSrc={ app.smallIconUrl }
        id={ app.tenant }
        name={ app.name }
        uri={ app.uri }
        onBecomeActive={ () => changeSidebarColor(app.color) }
        onClick={ toggleMobileMenu }
        disabled={ isDisabled }
        isActive={ asPath.startsWith(app.uri) }
        isExactActive
        nameCallback={ app.nameCallback }
        onRefreshClick={ () => refreshApplication(app.tenant) }
        { ...app.canCreate && ({
          addNewPathname,
          addNewTooltip,
        }) }
      />
    );
  };

  const renderPreloader = (amount: number = 1): React.ReactNode[] => {
    let preloaders = [];
    for (let i = 0; i < amount; i++) {
      preloaders.push(<Preloader key={ i } />);
    }

    return preloaders;
  };

  const isNavigationContentFetching = (isSidebarAppsFetching && !applicationCollection.length);
  const tenants = config.tenant;

  const isAccountsAndContactsAnalyticsVisible = [
    tenants.allocate.code,
    tenants.dealroadshow.code,
    tenants.orderBook.code,
    tenants.tenantEvercall.code,
    tenants.tenantDealvdr.code,
    tenants.tenantInvestorSet.code,
    tenants.tenantVeriSend.code,
    tenants.tenant17g5.code,
  ].some((tenant) => isTenantActivated(tenant, applicationCollection));

  const isAnalyticsVisible = isGlobalAnalyticsVisible(applicationCollection, userHasAccess);

  const appsTitleCls = cn(
    textStyles.smallTitle,
    styles.navSectionTitle,
    { [styles.isSidebarCollapsed]: isSidebarCollapsed },
  );

  return (
    <>
      { isNavigationContentFetching
        ? renderPreloader(1)
        : isAccountsAndContactsAnalyticsVisible && renderAccountsAndContactsAnalyticsLink() }
      { isNavigationContentFetching
        ? renderPreloader(1)
        : isAnalyticsVisible && renderAnalyticsLink() }
      <div className={ appsTitleCls }>
        { isSidebarCollapsed ? 'Apps' : 'My applications' }
      </div>
      { isNavigationContentFetching
        ? renderPreloader(4)
        : renderApplications() }
    </>
  );
};

export default Navigation;
