import cn from 'classnames';
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { isTabKey } from '@/Framework/browser/checkPressedKey';
import Highlighter from 'react-highlight-words';
import { ICompany } from '@/users/domain/vo/Company';
import { ICollectionItem, IAccount } from '@/allocate/domain/vo/Allocations/Upload/LinkAccounts';
import styles from '@/allocate/ui/components/shared/Upload/LinkAccounts/SelectAccount/selectAccount.scss';
import Input, { inputStyles } from '@dealroadshow/uikit/core/components/Input';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import { iconStyles } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  row: ICollectionItem,
  rowIndex: number,
  linkAccount: (account: IAccount | null, rowIndex: number) => void,
  showModal: (rowIndex: number) => void,
  getCompaniesList: (query: string) => void,
  companiesList: ICompany[],
}

const SelectAccount = ({
  row,
  rowIndex,
  linkAccount,
  showModal,
  getCompaniesList,
  companiesList,
}: IProps) => {
  const {
    accounts,
    mappedAccount,
    isAutoSelected,
  } = row;

  const [inputValue, setInputValue] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!inputRef.current?.contains(event.target)) {
        setIsDropdownOpened(false);
      }
    };

    const handleKeyPress = (event) => {
      if (isTabKey(event)) {
        setIsDropdownOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, false);
    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (mappedAccount) {
      setInputValue(mappedAccount.name);
      setSelectedAccountName(mappedAccount.name);
    } else {
      setInputValue('');
      setSelectedAccountName('');
    }
  }, [row]);

  const handleSelectAccount = (account: IAccount) => {
    setSelectedAccountName(account.name);
    setInputValue(account.name);
    setIsDropdownOpened(false);
    linkAccount(account, rowIndex);
  };

  const handleOpenModal = () => {
    setIsDropdownOpened(false);
    showModal(rowIndex);
  };

  /**
   * @param {Object} event
   */
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setInputValue('');
      setSelectedAccountName('');
      linkAccount(null, rowIndex);
    } else {
      setInputValue(event.target.value);
    }
    if (event.target.value.length >= 3) {
      getCompaniesList(event.target.value);
    }
  };

  const onFocus = () => {
    if (selectedAccountName && isAutoSelected) {
      setInputValue('');
      setSelectedAccountName('');
      linkAccount(null, rowIndex);
    }
    setIsDropdownOpened(true);
  };

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedAccountName) {
      setInputValue('');
    }
    if (selectedAccountName && event.target.value !== selectedAccountName) {
      setInputValue(selectedAccountName);
    }
  };

  const renderDropdown = () => {
    let options = null;
    if (inputValue === '') {
      options = accounts.map((account) => (
        <div
          className={ styles.option }
          onClick={ () => handleSelectAccount(account) }
          onMouseDown={ (event) => event.preventDefault() }
          key={ account.id }
        >
          { account.name }
        </div>
      ));
    } else if (inputValue.length >= 3 && companiesList) {
      options = companiesList.map((account) => (
        <div
          className={ styles.option }
          onClick={ () => handleSelectAccount(account) }
          onMouseDown={ (event) => event.preventDefault() }
          key={ account.id }
        >
          <Highlighter
            autoEscape
            searchWords={ [inputValue] }
            textToHighlight={ account.name }
            highlightClassName={ inputStyles.matchingText }
          />
        </div>
      ));
    }

    return (
      <div className={ styles.dropdown }>
        <div className={ styles.scrollWrapper }>
          { options }
        </div>
        <div
          className={ styles.addAccountOption }
          onClick={ handleOpenModal }
        >
          Don't see your account?
        </div>
      </div>
    );
  };

  const renderAdditionalInfo = () => {
    if (!selectedAccountName && accounts.length) {
      return (
        <div className={ styles.suggestionsCount }>
          { accounts.length } Potential { accounts.length > 1 ? 'Matches' : 'Match' }
        </div>
      );
    }

    if (selectedAccountName) {
      return <IconCheck className={ cn(styles.checkIcon, iconStyles.smallIcon) } />;
    }

    return null;
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.inputWrapper } ref={ inputRef }>
        { /* @ts-ignore */ }
        <Input
          isNarrow
          placeholder="Unmapped"
          inputClassName={ cn(styles.input, {
            [styles.opened]: isDropdownOpened,
          }) }
          value={ inputValue }
          onChange={ onChange }
          onFocus={ onFocus }
          onBlur={ onBlur }
          dataTest="selectAccountInput"
        />
        { isDropdownOpened && renderDropdown() }
      </div>
      { renderAdditionalInfo() }
    </div>
  );
};

export default SelectAccount;
