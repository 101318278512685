import React from 'react';
import memoize from '@/Framework/dataHelpers/memoize';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import keyResolver from '@/Framework/dataHelpers/keyResolver';
import SharedFilterGroupItem from '@/Framework/UI/Organisms/Filters/components/FilterGroupItem';
import { tenantsConfig } from '@/dmPortal/application/GlobalAnalytics/config';

const groupTitleByTenant = {
  [tenantsConfig.evercall.code]: 'Evercall',
  [tenantsConfig.dealvdr.code]: 'DealVDR',
  [tenantsConfig.investorset.code]: 'InvestorSet',
  [tenantsConfig.verisend.code]: 'VeriSend',
  [tenantsConfig['17g5'].code]: '17g5.com',
  [tenantsConfig.researchroom.code]: 'ResearchRoom',
  allocate_and_roadshow: 'Allocate & Deal Roadshow',
};

const getChildren = memoize((childrenIds, transactionTypesById, formValues, filterByChildrenName) => {
  const children = childrenIds.map((transactionTypeId) => ({
    value: transactionTypeId,
    label: transactionTypesById[transactionTypeId].transactionTypeName,
    disabled: !transactionTypesById[transactionTypeId].canChangeResponse,
  }));
  const activeChildrenCount = children.filter((child) => !child.disabled).length;
  const checkedChildrenCount = children.filter(
    (child) => !child.disabled && formValues[filterByChildrenName].includes(child.value),
  ).length;

  return {
    children,
    activeChildrenCount,
    checkedChildrenCount,
  };
}, keyResolver);

const FilterGroupItem = (props) => {
  const { value, formValues, filterByChildrenName, childrenIds } = props;

  const { transactionTypeFilterList } = useGlobalAnalyticsFiltersContext();

  const { children, activeChildrenCount, checkedChildrenCount } = getChildren(
    childrenIds,
    transactionTypeFilterList?.byId,
    formValues,
    filterByChildrenName,
  );

  return (
    <SharedFilterGroupItem
      { ...props }
      groupTitle={ groupTitleByTenant[value] }
      isDisabled={ children?.every(({ disabled }) => disabled) }
      nestedIds={ childrenIds }
      activeChildrenCount={ activeChildrenCount }
      checkedChildrenCount={ checkedChildrenCount }
      withCounter={ !(value === tenantsConfig['17g5'].code) }
      isExpanded
    >
      { children }
    </SharedFilterGroupItem>
  );
};

export default FilterGroupItem;
