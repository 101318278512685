import { ReactNode } from 'react';
import cn from 'classnames';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import IconInfo from '@dealroadshow/uikit/core/components/Icon/IconInfo';

import styles from './labelWithIcon.scss';

interface IProps {
  label: string,
  content: string | ReactNode,
  icon?: IconComponentType,
  labelClassName?: string,
  tooltipClassName?: string,
}

const LabelWithIcon = ({
  label,
  content,
  icon: IconComponent = IconInfo,
  labelClassName,
  tooltipClassName,
}: IProps) => (
  <>
    <span className={ labelClassName }>{ label }</span>
    <Tooltip content={ content } containerClassName={ cn(styles.tooltipIcon, tooltipClassName) }>
      <IconComponent />
    </Tooltip>
  </>
);

export default LabelWithIcon;
