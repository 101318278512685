import cn from 'classnames';
import React from 'react';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataTable, { SortOrder, dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import { ICollectionItem } from '@/allocate/domain/vo/Allocations/Upload/TemporaryAllocations';
import columns from '@/allocate/ui/components/shared/Upload/ConfirmAllocations/columns';
import styles from '@/allocate/ui/components/shared/Upload/ConfirmAllocations/confirmAllocations.scss';

interface IProps {
  collection: ICollectionItem[],
  isFetching: boolean,
  sortBy: string,
  sortOrder: SortOrder,
  onSortChange: (sortBy: string, sortOrder: SortOrder) => void,
  selectedClass: string,
  currencyDisplayNames: string[],
  containerClassName: string,
}

const TemporaryAllocationsTable = (props: IProps) => {
  const {
    collection,
    isFetching,
    sortBy,
    sortOrder,
    onSortChange,
    selectedClass,
    currencyDisplayNames,
    containerClassName,
  } = props;

  return (
    <div className={ cn(styles.horizontalScrollContainer, containerClassName) }>
      <DataTable
        containerClassName={ styles.tableContainer }
        className={ dataTableStyles.isStripedOdd }
        dataTest="temporaryAllocationsDataTable"
        columns={ columns(
          currencyDisplayNames,
          selectedClass,
        ) }
        data={ collection }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
        onSortChange={ onSortChange }
        loadingComponent={ Spinner }
        isFetching={ isFetching }
        isScrollable
      />
    </div>
  );
};

export default TemporaryAllocationsTable;
