import '@/Framework/browser/polyfill';
import { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { Store } from 'redux';
import Head from 'next/head';
import GlobalStyles, { styles } from '@/dmPortal/ui/common/GlobalStyles';
import Container from '@/Framework/DI/Container';
import TimeZoneContextProvider from '@/Framework/TimeZone/TimeZoneContext';
import Bootstrap, { healthCheck, wrapper } from '@/Framework/Bootstrap';
import reducer from '@/dmPortal/reducer';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import ApplicationWrp from '@/Framework/UI/Templates/ApplicationWrp';
import { Provider } from 'react-redux';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import Layout from '@/dmPortal/ui/common/Layout';
import ProjectLayout from '@/dmPortal/ui/common/Layout/ProjectLayout';
import CommonLayout from '@/dmPortal/ui/common/Layout/CommonLayout';

const App = ({ Component, pageProps, container, store }: AppProps<IGlobalProps> & {
  container: Container,
  store: Store,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      {
        isMounted && (
          <GlobalStyles>
            <Head>
              <title />
              <meta charSet="utf-8" />
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta
                httpEquiv="Cache-Control"
                content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
              />
              <meta httpEquiv="Pragma" content="no-store" />
              <meta httpEquiv="Expires" content="0" />
              <meta
                content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
                name="viewport"
              />
              <meta name="HandheldFriendly" content="true" />
            </Head>
            { /* @ts-ignore */ }
            <ErrorBoundary fallback={ ApplicationFallback }>
              <Bootstrap
                container={ container }
                session={ pageProps?.session }
                store={ store }
                cookies={ pageProps?.cookies }
              >
                <GlobalProps { ...pageProps }>
                  <TimeZoneContextProvider>
                    <ApplicationWrp className={ styles.body }>
                      <Provider store={ store }>
                        <Layout>
                          <CommonLayout>
                            <ProjectLayout>
                              <Component { ...pageProps } />
                            </ProjectLayout>
                          </CommonLayout>
                        </Layout>
                      </Provider>
                    </ApplicationWrp>
                  </TimeZoneContextProvider>
                </GlobalProps>
              </Bootstrap>
            </ErrorBoundary>
          </GlobalStyles>
        )
      }
    </>
  );
};

export default healthCheck(wrapper(reducer).withRedux(App));
