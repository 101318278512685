import config from '@/dataroom/application/config/config';

export const transactionTypes = {
  [config.tenant.tenantDealvdr.code]: {
    Other: {
      id: 'd891af7b-98c0-4419-8c19-2be5940cf1f1',
      displayName: 'Other (Excluded from Analytics)',
    },
  },
  [config.tenant.tenant17g5.code]: {
    Other: {
      id: '1137491b-dc9a-4333-92b0-1f8e7fbde70f',
      displayName: 'Other (Excluded from Analytics)',
    },
  },
  [config.tenant.tenantInvestorSet.code]: {
    Other: {
      id: 'c097925e-f4ff-4e06-b4fd-73dd9adb2d6b',
      displayName: 'Other (Excluded from Analytics)',
    },
  },
  [config.tenant.tenantVeriSend.code]: {
    Other: {
      id: 'd17d64e4-3006-4318-8d5d-003ff4846c4e',
      displayName: 'Other (Excluded from Analytics)',
    },
  },
  // todo: check for RR
  [config.tenant.tenantResearchRoom.code]: {
    Other: {
      id: 'd17d64e4-3006-4318-8d5d-003ff4846c4e',
      displayName: 'Other (Excluded from Analytics)',
    },
  },
};

export const PCR_TIP = 'Creating an Private Credit Rating - Regulation Data Room will limit the number of receiving parties to 150 natural or legal persons.';

const PCR_ID = '3a47f3e9-14a2-48c4-ad7b-8bfb2da9ddfe';

export const isPCRId = (value) => value === PCR_ID;
