import React from 'react';
import cn from 'classnames';
import DataTable, { dataTableStyles } from '@dealroadshow/uikit/core/components/Table/DataTable';
import columns from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/equityColumns';
import { IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import styles from '@/allocate/ui/components/shared/Analytics/SecurityDetails/securityDetails.scss';

interface IProps {
  securityDetails: IEquitySecurities,
}

const EquityTable = ({ securityDetails }: IProps) => {
  const {
    securities,
    sharesAtLaunch,
  } = securityDetails;

  const columnsConfig = {
    hasOrders: !!sharesAtLaunch,
    hasRangeLow: securities.some((security) => security.rangeLow),
    hasRangeHigh: securities.some((security) => security.rangeHigh),
  };

  return (
    <DataTable
      className={ cn(dataTableStyles.isStripedOdd, styles.dataTable) }
      data={ securities }
      columns={ columns(columnsConfig) }
      dataTest="securityDetailsDataTable"
    />
  );
};

export default EquityTable;
