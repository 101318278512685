import React from 'react';
import cn from 'classnames';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import IconPlus from '@dealroadshow/uikit/core/components/Icon/IconPlus';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import BaseIcon from '@dealroadshow/uikit/core/components/Icon/BaseIcon';
import styles from './appNavigation.scss';
import hiddenStyles from '@dealroadshow/uikit/core/styles/helpers/hidden.scss';

interface IProps {
  isSubItem?: boolean,
  isSidebarCollapsed: boolean,
  iconSrc?: string,
  icon?: IconComponentType,
  id: string,
  name: string,
  uri: string,
  onClick: () => void,
  isActive?: boolean,
  parent?: string,
  children?: React.ReactNode,
  isExternal?: boolean,
  showChildrenWhenCollapsed?: boolean,
  onBecomeActive?: () => void,
  className?: string,
  activeClassName?: string,
  disabled?: boolean,
  nameCallback?: () => string | React.ReactNode,
  onRefreshClick?: () => void,
  addNewPathname?: string,
  addNewTooltip?: string,
  isExactActive?: boolean,
}

const AppNavigationItem = (
  {
    isSubItem,
    isSidebarCollapsed,
    iconSrc,
    icon: Icon,
    id,
    name,
    uri,
    onClick,
    isActive = window.location.pathname.includes(uri),
    parent,
    children = null,
    isExternal,
    showChildrenWhenCollapsed = false,
    onBecomeActive,
    className,
    activeClassName,
    disabled,
    nameCallback,
    onRefreshClick,
    addNewPathname,
    addNewTooltip,
    isExactActive,
  }: IProps,
) => {
  const activeCls = cn(styles.isActive, activeClassName);
  const navLinkCls = cn(
    styles.navItem,
    { [styles.isSub]: isSubItem },
    { [styles.noAppInformation]: disabled },
    className,
  );

  const content = (
    // @ts-ignore
    <Tooltip
      placement="bottom"
      content={ name }
      disabled={ !isSidebarCollapsed }
      className={ styles.tooltipContentContainer }
      wrpClassName={ styles.tooltipWrp }
      containerClassName={ cn(styles.tooltipContainer, { [styles.isTooltipActive]: isSidebarCollapsed }) }
      persistInDom
    >
      { iconSrc ? (
        <BaseIcon src={ iconSrc } className={ styles.navItemIcon } />
      ) : (
        <Icon className={ styles.navItemIcon } />
      ) }
      { nameCallback
        ? nameCallback()
        : (
          <span className={ cn(
            styles.navItemText,
            { [styles.textHidden]: isSidebarCollapsed },
          ) }
          >
            { name }
          </span>
        ) }
    </Tooltip>
  );

  const actions = (
    <>
      { disabled && onRefreshClick && (
        <button
          type="button"
          className={ styles.refreshBtn }
          onClick={ onRefreshClick }
          data-test="appNavigationRotateCounterclockwiseButton"
        >
          <IconRotate />
        </button>
      ) }
      { !disabled && addNewPathname && (
        <NextNavLink
          to={ `${ uri }${ addNewPathname }` }
          className={ styles.actionBtn }
          dataTest="appNavigationAddNewNavLink"
          isActive={ isActive }
          isExactActive={ isExactActive }
        >
          <Tooltip content={ addNewTooltip } hideOnClick>
            <IconPlus />
          </Tooltip>
        </NextNavLink>
      ) }
    </>
  );

  let navLink = (
    <div
      className={ styles.navItemWrapper }
      data-test={ `${ id }NavLink` }
    >
      <NextNavLink
        to={ `${ uri }` }
        className={ navLinkCls }
        activeClassName={ activeCls }
        key={ id }
        onClick={ onClick }
        onBecomeActive={ onBecomeActive }
        isActive={ isActive }
        isExactActive={ isExactActive }
        disabled={ disabled }
        dataTest="NavLInk"
      >
        { content }
      </NextNavLink>
      { actions }
    </div>
  );

  if (isExternal) {
    navLink = (
      <div className={ styles.navItemWrapper }>
        <a
          className={ cn(
            navLinkCls,
            { [activeCls]: isActive },
          ) }
          href={ uri }
          target="_self"
          onClick={ onClick }
        >
          { content }
        </a>
        { actions }
      </div>
    );
  }

  if (!parent && children) {
    return (
      <div
        key={ id }
        id={ id }
        aria-expanded={ isActive }
        data-test={ `${ id }Panel` }
      >
        { navLink }
        <div className={ cn({
          [styles.isPanelContentHidden]: (!children || isSidebarCollapsed || !isActive) &&
          !showChildrenWhenCollapsed,
        }) }
        >
          <div
            className={ cn({ [hiddenStyles.hidden]: !isActive }) }
            role="tabpanel"
          >
            { children }
          </div>
        </div>
      </div>
    );
  }

  return navLink;
};

export default AppNavigationItem;
