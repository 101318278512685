import React from 'react';
import Head from 'next/head';
import PermissionsContextProvider from '@/allocate/application/Allocations/PermissionsContext';
import useDrsAllocations from '@/allocate/ui/components/Dealroadshow/Allocations/useDrsAllocations';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from '@/allocate/ui/components/Dealroadshow/Allocations/allocations.scss';

interface IProps {
  children: React.ReactNode,
}

const AllocationsTab = ({ children }: IProps) => {
  const { isFetching } = useDrsAllocations();

  return (
    <>
      <Head>
        <title>Allocation Analytics | Deal Roadshow</title>
      </Head>
      { isFetching ? (
        <div className={ styles.initialPreloaderWrapper }>
          <Spinner centered />
        </div>
      ) : (
        <PermissionsContextProvider>
          { children }
        </PermissionsContextProvider>
      ) }
    </>
  );
};

export default AllocationsTab;
