import React from 'react';
import { Label } from '@dealroadshow/uikit/core/components/Badge/Label';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { mapTenantToColor } from '@/dmPortal/ui/components/AccountsAndContacts/constants';
// @ts-ignore
import styles from './appLabel.scss';
import TenantConfig from '@/Framework/Tenant/TenantConfig';

interface IProps {
  tenant: string,
}

const AppLabel = ({ tenant }: IProps) => {
  const {
    name,
    abbr: abbreviationApp,
  } = TenantConfig.fromCode(tenant);
  return (
    <Tooltip content={ name }>
      <Label
        className={ styles.appLabelBadge }
        backgroundColor={ mapTenantToColor[tenant] }
        isBold
      >
        { abbreviationApp }
      </Label>
    </Tooltip>
  );
};

export default AppLabel;
