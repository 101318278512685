import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import { useGlobalAnalyticsContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import { IAppliedFilters } from '@/dmPortal/application/GlobalAnalytics/filters/types';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import { tenantLabels, filterDealsBy } from './constants';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconSearch from '@dealroadshow/uikit/core/components/Icon/IconSearch';
import DealsList from './DealsList';
import filtersStyles from '@/Framework/UI/Organisms/Filters/filters.scss';
import dealsStyles from './deals.scss';

interface IProps {
  filtersPayload: IAppliedFilters,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (sectionId: string) => void,
  resetTimestamp: string,
}

const Deals = ({
  sectionId,
  sectionTitle,
  isVisible,
  filtersPayload,
  onSectionToggle,
  resetTimestamp,
}: IProps) => {
  const {
    dealsFilters: { deals },
  } = useGlobalAnalyticsFiltersContext();
  const { dealsSupportedTenants } = useGlobalAnalyticsContext();

  const isFirstRender = useRef(true);

  const [searchString, setSearchString] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(searchString);
  const debounceSearch = useCallback(debounce(setDebouncedSearch, 300), []);
  const handleSearchChange = ({ target: { value } }) => {
    setSearchString(value);
    debounceSearch(value);
  };

  const tenantOptions = useMemo(() => {
    return dealsSupportedTenants.map((tenant) => ({
      value: tenant,
      label: tenantLabels[tenant] as string,
    }));
  }, [dealsSupportedTenants]);

  const [selectedTenants, setSelectedTenants] = useState<typeof tenantOptions>([]);
  const selectedTenantsIds = useMemo(() => selectedTenants.map((tenant) => tenant.value), [selectedTenants]);

  const selectedValues = useMemo(() => {
    return dealsSupportedTenants.reduce<string[]>((array, tenant) => {
      return [...array, ...filtersPayload[filterDealsBy[tenant]]];
    }, []);
  }, [dealsSupportedTenants, filtersPayload]);

  useEffect(() => {
    if (isFirstRender.current && tenantOptions.length > 0) {
      setSelectedTenants(tenantOptions);
      isFirstRender.current = false;
    }
  }, [tenantOptions]);

  useEffect(() => {
    if (resetTimestamp) {
      setSearchString('');
      debounceSearch('');
    }
  }, [resetTimestamp]);

  const headerComponent = () => (
    <label data-test={ sectionId } htmlFor={ sectionId } className={ dealsStyles.labelWrapper }>
      <div className={ dealsStyles.title }>{ sectionTitle }</div>
    </label>
  );

  if (deals.length === 0) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent() }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <Select
        formFieldClassName={ dealsStyles.select }
        placeholder="Select Applications"
        dataTest="dealsApplicationsSelect"
        value={ selectedTenants }
        onChange={ setSelectedTenants }
        options={ tenantOptions }
        searchable={ false }
        clearable={ false }
        closeMenuOnSelect={ false }
        hideSelectedOptions={ false }
        supportHighlighting={ false }
        isMulti
        hasOneOption
      />
      { /* @ts-ignore */ }
      <Input
        name="searchByDealName"
        icon={ IconSearch }
        isNarrow
        placeholder="Filter by deal name"
        value={ searchString }
        onChange={ handleSearchChange }
        className={ filtersStyles.filterSearchInput }
        dataTest="dealsInput"
      />
      <DealsList
        search={ debouncedSearch }
        selectedValues={ selectedValues }
        selectedTenants={ selectedTenantsIds }
      />
    </FilterSection>
  );
};

export default Deals;
