import React from 'react';
import isEmpty from 'lodash/isEmpty';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import columns from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsHeader/equityColumns';
import { IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import styles from '@/allocate/ui/components/shared/Analytics/SecurityDetails/securityDetails.scss';

interface IProps {
  securityDetails: IEquitySecurities,
}

const EquityHeader = ({ securityDetails }: IProps) => {
  const {
    issuerEntity,
    sharesAtLaunch,
    subscriptionLevel,
    totalProceeds,
    issuePricingDate,
    transactionTypeId,
    industry,
    equityType,
    underwriters,
  } = securityDetails;

  const securityType = {
    industry,
    equityType,
    transactionTypeId,
  };

  const columnsConfig = {
    hasOrders: !!(sharesAtLaunch || sharesAtLaunch === 0),
    hasUnderwriters: !isEmpty(underwriters),
    rowsCount: totalProceeds.length,
    totalProceeds,
  };

  const collection = totalProceeds.map((row, index) => {
    if (index === 0) {
      return {
        issuerEntityName: issuerEntity.name,
        underwriters,
        totalProceeds: totalProceeds[index],
        sharesAtLaunch,
        subscriptionLevel,
        issuerEntityTicker: issuerEntity.ticker,
        issuePricingDate,
        securityType,
      };
    }

    return {
      totalProceeds: totalProceeds[index],
    };
  });

  return (
    <DataTable
      className={ styles.headerTable }
      data={ collection }
      columns={ columns(columnsConfig) }
      dataTest="securityDetailsGeneralInfoDataTable"
    />
  );
};

export default EquityHeader;
