import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import LabelWithIcon from '@/dmPortal/ui/common/LabelWithIcon';

const TOOLTIP_CONTENT_INVITE_BY_EMAIL = 'Deal Manager will be sent a notification ' +
  'email with details to access these allocations.';
const TOOLTIP_CONTENT_ANALYTICS_ONLY = 'Restrict Deal Manager access to only view analytics. ' +
  'The Deal Manager will not be able to manage or edit the allocations.';

const fieldsList = [
  {
    name: 'inviteByEmail',
    component: CheckboxInput,
    dataTest: 'inviteByEmailCheckbox',
    type: 'checkbox',
    label: <LabelWithIcon label="Invite by Email" content={ TOOLTIP_CONTENT_INVITE_BY_EMAIL } />,
  },
  {
    name: 'analyticsOnly',
    component: CheckboxInput,
    dataTest: 'analyticsOnlyCheckbox',
    type: 'checkbox',
    label: <LabelWithIcon label="Analytics Only" content={ TOOLTIP_CONTENT_ANALYTICS_ONLY } />,
  },
];

export default fieldsList;
