import { ChangeEvent, useEffect, useState } from 'react';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import shouldDivideSortBy from '@/allocate/ui/helpers/shouldDivideSortBy';
import { ICollectionItem } from '@/allocate/domain/vo/Allocations/Upload/TemporaryAllocations';
import { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/SortOrder';
import { ALL_CLASSES_NAME } from '@/allocate/ui/constants';
import { tenantDealroadshow } from '@/allocate/application/config';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';

const useConfirmAllocations = () => {
  const { container } = useDIContext();
  const {
    tenant,
    dealAllocationId,
    allocationsType,
    linkedRoadshowId,
  } = useUploadContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);

  const [allocationsClasses, setAllocationsClasses] = useState<string[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>(null);
  const [sortBy, setSortBy] = useState('leiName');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [collection, setCollection] = useState<ICollectionItem[]>([]);
  const [currencyDisplayNames, setCurrencyDisplayNames] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetched, setIsFetched] = useState(false);

  /**
   * Get temporary allocations classes
   */
  const getTemporaryAllocationsClasses = async (): Promise<void> => {
    try {
      const { classNames } = await allocationsUploadRepository.getTemporaryAllocationsClasses(tenant, {
        dealAllocationId,
        allocationType: allocationsType,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });

      if (classNames.length > 1) {
        classNames.unshift(ALL_CLASSES_NAME);
      }

      setSelectedClass(classNames[0]);
      setAllocationsClasses(classNames);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Get temporary allocations
   */
  const getTemporaryAllocations = async (): Promise<void> => {
    setIsFetching(true);
    try {
      const className = selectedClass !== ALL_CLASSES_NAME ? selectedClass : null;
      let sortByValue = sortBy;
      let sortCurrency;

      if (shouldDivideSortBy(sortBy)) {
        [sortByValue, sortCurrency] = sortBy.split('-');
      }

      // TODO Allocations refactoring: change response from backend in case of empty collection
      const response = await allocationsUploadRepository.getTemporaryAllocations(tenant, {
        className,
        sortOrder,
        search: searchInputValue,
        sortBy: sortByValue,
        sortByCurrency: sortCurrency,
        dealAllocationId,
        allocationType: allocationsType,
        ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
      });

      if (response) {
        const {
          temporaryAllocationsStatsCollection,
          currencyDisplayNames,
        } = response;
        setCollection(temporaryAllocationsStatsCollection);
        setCurrencyDisplayNames(currencyDisplayNames);
      } else {
        setCollection([]);
        setCurrencyDisplayNames([]);
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
      setIsFetched(true);
    }
  };

  /**
   * Search allocations
   */
  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchInputValue(event.target.value);
  };

  /**
   * Sort allocations table
   */
  const handleSortChange = (sortBy: string, sortOrder: SortOrder): void => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  /**
   * Change allocations class
   */
  const handleClassChange = (className: string): void => {
    setSelectedClass(className);
    setSortBy('leiName');
    setSortOrder('asc');
  };

  useEffect(() => {
    if (dealAllocationId) {
      getTemporaryAllocationsClasses();
    }
  }, []);

  useEffect(() => {
    if (selectedClass) {
      getTemporaryAllocations();
    }
  }, [selectedClass, sortBy, sortOrder, searchInputValue]);

  return {
    isFetching,
    isFetched,
    selectedClass,
    sortBy,
    sortOrder,
    searchInputValue,
    allocationsClasses,
    collection,
    currencyDisplayNames,
    handleSortChange,
    handleClassChange,
    handleSearch,
  };
};

export default useConfirmAllocations;
