export enum AllocateTab {
  GlobalAnalyticsByAllocate = 'by_allocate',
  GlobalAnalyticsByAccount = 'by_account',
  GlobalAnalyticsByTranche = 'by_tranche',
  Allocate = 'allocate',
  All = 'all',
}

export enum DataroomTab {
  MySets = 'my_sets',
  MyResearches = 'my_researches',
  GlobalAnalyticsByDataroom = 'by_dataroom',
  GlobalAnalyticsByAccount = 'by_account',
  ExploreSets = 'explore_sets',
  ExploreResearches = 'explore_researches',
  All = 'all',
  AnalyticsDealDigest = 'deal_digest',
  AnalyticsAccounts = 'accounts',
  AnalyticsContacts = 'contacts',
}

export enum RoadshowTab {
  Roadshow = 'roadshow',
  Roadshows = 'roadshows',
  GlobalAnalyticsByRoadshow = 'by_roadshow',
  GlobalAnalyticsByAccount = 'by_account',
  AnalyticsDealDigest = 'deal_digest',
  AnalyticsAccounts = 'accounts',
  AnalyticsContactsAccessed = 'accessed',
  AnalyticsContactsInvited = 'invited',
  AnalyticsAllocations = 'allocations',
  AnalyticsEngagement = 'engagement',
  AnalyticsTargeting = 'investor-targeting',
  AnalyticsQuestions = 'questions',
  AnalyticsMeetings = 'meetings',
  Edit = 'edit',
  AuditTrail = 'audit-trail',
}

export enum EvercallTab {
  Evercall = 'evercall',
  GlobalAnalyticsByEvercall = 'by_evercall',
  GlobalAnalyticsByAccount = 'by_account',
  Analytics = 'analytics',
  Upload = 'upload',
  RegisteredParticipants = 'registered-participants',
  Edit = 'edit',
  Settings = 'settings',
  AuditTrail = 'audit-trail',
  RestrictAccess = 'restrict-access',
}

export enum AccountsTab {
  Accounts = 'accounts',
  Account = 'account',
  Contacts = 'contacts/accessed',
  InvitedContacts = 'contacts/invited',
  Contact = 'contact',
  MyAccounts = 'my_accounts',
  MyContacts = 'my_contacts',
  Activity = 'activity',
  Notes = 'notes',
  Teams = 'teams',
  MyTeams = 'my_teams',
  CreateTeam = 'create',
}

export enum OrderbookTab {
  Orderbook = 'orderbook',
  Orders = 'orders',
  Allocations = 'allocations',
  TransactionDetails = 'transaction-details',
  AuditTrail = 'audit-trail',
  Edit = 'edit',
}

export enum GlobalAnalytics {
  Analytics = 'analytics',
}

export enum RouteList {
  Datarooms = '/datarooms/[tenant]',
  GlobalAnalytics = '/analytics',
  RoadshowAnalytics = '/roadshow/[roadshowId]/analytics',
  Allocate = '/allocate',
  Roadshow = '/roadshow',
  Accounts = '/accounts',
  OrderBook = '/orderbook',
  Evercall = '/evercall',
}

export enum CommonLayoutRoute {
  Roadshow = '/roadshow',
  Evercall = '/evercall',
  Datarooms = '/datarooms',
  Allocate = '/allocate',
  OrderBook = '/orderbook',
}
