import React, { ReactNode } from 'react';
import cn from 'classnames';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from '@/allocate/ui/components/shared/Upload/upload.scss';

interface IProps {
  children: ReactNode,
  footer?: ReactNode,
  isFetching?: boolean,
}

const StepWrapper = ({
  isFetching = false,
  children,
  footer,
}: IProps) => (
  <div className={ styles.wrapper }>
    <Spinner overlay isVisible={ isFetching } />
    <div
      className={ cn(styles.childrenContainer, { [styles.isTransparent]: isFetching }) }
    >
      { children }
    </div>
    { footer && (
      <div className={ cn(styles.footer, { [styles.isTransparent]: isFetching }) }>
        { footer }
      </div>
    ) }
  </div>
);

export default StepWrapper;
