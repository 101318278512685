import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FilterColumns from '@/Framework/UI/Organisms/Filters/components/FilterColumns';
import FilterGroupItem from './FilterGroupItem';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (section: string) => void,
}

const filterByName = 'includeAbsSectorIds';
const filterByChildrenName = 'includeAbsSubSectorIds';

function AbsSector({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const {
    absSectorFilterList,
    absSubSectorFilterList,
  } = useGlobalAnalyticsFiltersContext();

  const absSubSectorById = absSubSectorFilterList?.byId;
  const allSubIds = useMemo(
    () => absSubSectorFilterList?.allIds.map((absSubSectorId) => ({
      value: absSubSectorId,
      disabled: !absSubSectorById[absSubSectorId]?.canChangeResponse,
    })),
    [absSubSectorFilterList?.allIds],
  );
  const activeSubSectorIds = useMemo(
    () => absSubSectorFilterList?.allIds.filter(
      (absSubSectorId) => absSubSectorById[absSubSectorId]?.canChangeResponse,
    ),
    [absSubSectorFilterList?.allIds],
  );

  let absSectorIdsUnchecked = useMemo(
    () => absSectorFilterList?.allIds.filter((value) => !formValues[filterByName]?.includes(value) &&
      !absSectorFilterList?.byId[value].canChangeResponse),
    [formValues[filterByName]],
  );

  const sectionIndeterminateState = useMemo(
    () => getIndeterminateState(allSubIds, formValues[filterByChildrenName]),
    [formValues[filterByChildrenName]],
  );

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        form.change(filterByName, [...formValues[filterByName], ...absSectorIdsUnchecked]);
        form.change(filterByChildrenName, [...formValues[filterByChildrenName], ...activeSubSectorIds]);
      } else {
        const subSectorIdsUnchecked = formValues[filterByChildrenName]
          .filter((investorId) => !activeSubSectorIds.includes(investorId));
        form.change(filterByName, []);
        form.change(filterByChildrenName, subSectorIdsUnchecked);
      }
    };

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        disabled={ activeSubSectorIds.length === 0 }
        { ...sectionIndeterminateState }
      />
    );
  }, [form, absSubSectorFilterList?.allIds, sectionIndeterminateState]);

  if (isEmpty(absSubSectorFilterList?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { absSectorFilterList?.allIds.map((value) => (
          <FilterGroupItem
            key={ value }
            form={ form }
            fieldComponent={ Field }
            formValues={ formValues }
            value={ value }
            filterByName={ filterByName }
            filterByChildrenName={ filterByChildrenName }
          />
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default AbsSector;
