import {
  allocationsSumCellCallback,
  defaultOptionalCellCallback,
  indexCellCallback,
  percentageCellCallback,
  responsiveOptionalCellCallback,
} from '@/allocate/ui/components/shared/Tables/cellCallbacks';
import { IColumn } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/Column';
import cellStyles from '@/allocate/ui/components/shared/Tables/cellStyles.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import { ALL_CLASSES_NAME } from '@/allocate/ui/constants';

const columns = (
  currencyDisplayNames: string[],
  selectedClass: string,
): IColumn[] => {
  const currencyColumns = currencyDisplayNames.flatMap((currency) => ({
    name: `allocationsSum-${ currency }`,
    title: `${ currency } Allocation`,
    width: 140,
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: (cellProps) => allocationsSumCellCallback(cellProps, currency),
  }));

  return [
    {
      name: 'index',
      title: '#',
      width: 38,
      cellCallback: indexCellCallback,
    },
    {
      name: 'leiName',
      title: 'Account (Orig)',
      sortable: true,
      cellCallback: defaultOptionalCellCallback,
      ...(selectedClass !== ALL_CLASSES_NAME && { width: 180 }),
    },
    {
      name: 'classNames',
      title: 'Classes',
      sortable: true,
      width: 100,
      className: alignStyles.alignRight,
      isVisible: selectedClass === ALL_CLASSES_NAME,
      cellCallback: defaultOptionalCellCallback,
    },
    {
      name: 'comment',
      title: 'Comments',
      sortable: true,
      minWidth: 120,
      className: cellStyles.responsiveCell,
      isVisible: selectedClass !== ALL_CLASSES_NAME,
      cellCallback: responsiveOptionalCellCallback,
    },
    ...currencyColumns,
    {
      name: 'allocationsSumPercentage',
      title: '% of tranche',
      className: alignStyles.alignRight,
      isVisible: selectedClass !== ALL_CLASSES_NAME,
      sortable: true,
      width: 120,
      cellCallback: percentageCellCallback,
    },
  ];
};

export default columns;
